import React from 'react'
import styled from 'styled-components'
import { PrismicLink } from '@prismicio/react'
import Link from 'next/link'
import {theme} from "../../../styles/globalStyles"

export interface IPrismicLink {
	label?: string
	id?: string
	isBroken?: boolean
	lang?: string
	link_type: string
	slug?: string
	type?: string
	uid?: string
	url?: string
	target?: string
	tags?: string[]
}

export interface IFooterLinkSlice {
	slice_type: 'footerlink'
	primary: {
		column: number
		link_text: string
		link: IPrismicLink
	}
}

interface IProps {
	slice: IFooterLinkSlice
	linkResolver?: (link: any) => string | undefined
}

export const FooterLink = ({ slice: { primary }, linkResolver }: IProps) => {
	return (
		<StyledPrismicLink
			linkResolver={linkResolver}
			// eslint-disable-next-line
			externalComponent={(props) => (
				<Link className='KUI-Prismic-ColumnFooter-FooterLink' {...props} />
			)}
			// eslint-disable-next-line
			internalComponent={(props) => (
				<Link className='KUI-Prismic-ColumnFooter-FooterLink' {...props} />
			)}
			field={primary.link}
		>
			{primary.link_text}
		</StyledPrismicLink>
	)
}

const StyledPrismicLink = styled(PrismicLink)`
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
	color: ${theme.colors.gold150};

	&:hover {
		text-decoration: underline;
	}
`
