import { createGlobalStyle, css } from 'styled-components'

import { media } from '../utils/style-utils'

export const theme = {
	colors: {
		primary: '#383164',
		primary100: '#E6E3FA',
		primary400: '#9A92CA',
		primary600: '#514790',
		secondary: '#F05A4F',
		white: '#FFFFFF',
		black: '#1B1830',
		beige: '#f1ece6',
		gray: '#e0e0e0',
		lightGray: '#f5f5f5',
		darkGray: '#9D9D9D',

		error: '#ce1d1d',
		errorLight: '#fadbdb',
		errorDark: '#811212',

		success: '#7ea859',
		successLight: '#ebf1e4',
		successDark: '#486133',

		warning: '#f39e1f',
		warning100: '#FDEED8',
		warningLight: '#fdeed8',
		warningDark: '#8c5708',

		gold50: '#faf9f6',
		gold100: '#f1ece3',
		gold150: '#e8dfd1',
		gold200: '#DED2BE',
		gold250: '#D3C2A7',
		gold300: '#CBB899',
		gold400: '#b89e74',
		gold500: '#a28352',
		gold550: '#907140', // hsl(37, 38%, 41%)
		gold600: '#79623D', // hsl(35, 33%, 42%)
		gold700: '#514129',
		gold750: '#3C311F',
		gold800: '#282014',

		grey50: '#FAFAFA',
		grey100: '#EBEAE9',
		grey200: '#cccac8',
		grey400: '#999794',
		grey600: '#666563',
		grey700: '#4a4948',
		grey800: '#3B3B3B',
		grey900: '#242424',
		greyBlack: '#3F3B37',
		goldenGrey400: "#BDB6AB",
		goldenGrey600: '#4F4C48',
		transparent: 'transparent',
		lightGold: 'rgba(157, 143, 123, 0.5)',
		darkGold: 'rgba(179, 137, 78, 0.1)',
		lightBeige: '#E8DFD1',
	},

	shadow: '0 .25rem 3.5rem rgba(0, 0, 0, 0.07)',

	containerWidths: {
		medium: '816px',
	},

	breakpoints: {
		smallMobileMax: '350px',
		mobileMid: '480px',
		mobileMax: '599px',
		tabletMin: '600px',
		tabletMid: '750px',
		tabletMax: '979px',
		desktopMin: '980px',
		desktopMinPlus: '1024px',
		desktopMid: '1150px',
		desktopMidPlus: '1270px',
		desktopMax: '1440px',
	},
	gutters: {
		gutterOff: '0',
		gutterDesktop: '9.75rem',
		gutterTabletDesktop: '2.5rem',
		gutterTablet: '3rem',
		gutterSmallMobile: '1rem',
		gutterMobile: '1.5rem',
	},
}

const typography = css`

	h1 {
		margin-top: 1.25rem; // 20 px
		margin-bottom: 1.25rem;
		font-size: 1.375rem; // 22px
		line-height: 1.5rem; // 24px;
		font-weight: 500;
		font-family: AkzidenzGroteskPro, sans-serif;

		@media only screen and (max-width: 767px) {
			font-size: 1.1875rem; // 19px
		}
	}

	// * The primary heading elements (as named by the design system) are used less frequently
	h1.primary {
		font-family: AdobeCaslonPro, serif;
		font-style: normal;
		font-weight: 600;
		font-size: 3.5625rem; // 57px
		line-height: 3.5rem; // 56px

		${media.phone`
			font-size: 2.0625rem; // 33px
		`};
	}

	h2,
	.h2 {
		font-family: AkzidenzGroteskPro, sans-serif;
		font-size: 1rem; // 16px
		font-weight: 500;
		line-height: 1.5rem; // 24px
		margin: 0;
		color: ${theme.colors.grey800};

		${media.phone`
			font-size: 1rem; // 16px
		`};
	}

	h2.primary {
		font-family: AdobeCaslonPro, serif;
		font-style: normal;
		font-weight: 600;
		font-size: 2.5rem; // 40px
		line-height: 3.5rem; // 56px

		${media.phone`
			font-size: 1.75rem; // 28px
		`};
	}

	h3 {
		font-family: AkzidenzGroteskPro, sans-serif;
		font-size: 0.8125rem; // 13px
		font-weight: 500;
		line-height: 1rem; // 16px
		margin: 1.25rem 0;
		color: #444;

		${media.phone`
			font-size: .8125rem //13px;
		`};
	}

	h3.primary {
		font-family: AdobeCaslonPro, serif;
		font-style: normal;
		font-weight: 600;
		font-size: 2.0625rem; // 33px
		line-height: 2.5rem; // 40px

		${media.phone`
			font-size: 1.4375rem; // 23px
		`};
	}

	// * H4 is not specified with an alternative variant
	h4 {
		font-family: AdobeCaslonPro, serif;
		font-size: 1.563rem; // 25px
		font-weight: 600;
		line-height: 2.5rem; // 40px
		margin: 0.5rem 0;
		color: #444;

		${media.phone`
			font-size: 1.188rem; // 19px;
		`};
	}

	h5 {
		font-family: AdobeCaslonPro, serif;
		font-size: 1.125rem; // 18px
		margin: 0.7813rem 0;
		font-weight: 500;
		line-height: 1.1;
	}

	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-family: AkzidenzGroteskPro, sans-serif;
		font-size: 1rem;
		line-height: 24px; // 28px
		font-weight: 400;
		color: ${theme.colors.grey800};
	}

	p,
	ul {
		font-weight: 400;
		font-size: 1rem; // 16px
		margin-block: 8px;
		line-height: 1.5rem; // 24px
		font-family: AkzidenzGroteskPro, sans-serif;
	}

	.body-large-serif {
		a {
			font-size: 1.375rem; // 22px
			line-height: 2rem; // 32px
			font-family: AdobeCaslonPro, Times, serif;
		}
		font-size: 1.375rem; // 22px
		line-height: 2rem; // 32px
		font-family: AdobeCaslonPro, Times, serif;
		color: ${theme.colors.grey600};
	}

	.body-medium-serif {
		a {
			font-size: 1.125rem;
			line-height: 2rem; // 32px
			font-weight: 400;
			font-family: AdobeCaslonPro, Times, serif;
		}
		font-size: 1.125rem;
		line-height: 2rem; // 32px
		font-weight: 400;
		font-family: AdobeCaslonPro, Times, serif;
		color: ${theme.colors.grey600};
	}

	a {
		color: ${theme.colors.gold550};
		text-decoration: underline solid transparent; // * to enable transition
		&:hover {
			text-decoration: underline solid currentColor;
			color: ${theme.colors.gold400};
			transition: var(--animation-transition);
		}
	}

	a.h2 {
		color: ${theme.colors.gold550};
	}

	ul {
		font-weight: 300;
	}

	.primary-color {
		color: ${theme.colors.gold550};
	}

	thead {
		border-bottom: 2px solid ${theme.colors.gold200};
	}
	th {
		font-weight: 500;
	}

	button {
		font-family: AkzidenzGroteskPro, sans-serif;
	}
`

const GlobalStyle = createGlobalStyle`
:root {
	--spacer-xs: 16px;
	--spacer-sm: 24px;
	--spacer-md: 32px;
	--spacer-lg: 56px;
	--spacer-base: 80px; // most commonly used
	--spacer-xl: 120px;
	--spacer-2xl: 200px;
	--animation-transition: 300ms;
}

/* Default styles */
${typography}
html,
body {
	margin:0;
}

* {
  box-sizing: border-box;
}

.grecaptcha-badge {
    visibility: hidden;
}

ul {
	margin-top: 0;
	margin-bottom: 12.5008px;
}

.centeredLayout {
	width: 100%;
	margin: 0 2rem;
	overflow-y: hidden;
	overflow-x: clip;
	@media (min-width: ${theme.breakpoints.desktopMin}){
    width: 970px;
		overflow-x:hidden;
	}
	@media (min-width: ${theme.breakpoints.desktopMidPlus}){
		width: 1170px;
		overflow-x:hidden;
	}
}
.navMenu {
	li {
		background-color: ${theme.colors.secondary};
		color: ${theme.colors.white};
		:hover {
			color: ${theme.colors.primary};
			font-weight: 600;
			background-color: ${theme.colors.secondary};
			img {
				filter: invert(0);
			}
		}
	}
}
.NumberInput {
    height: 3rem;
    padding: 1rem;
    font-size:1rem;
    border: 1px solid ${theme.colors.gray};
    border-radius:2px;
}
.NumberInputText {
    height: 2rem;
    padding: 1rem;
    font-size:1rem;
    border-radius:2px;
}
.SliderInput {

    color:${theme.colors.primary600};
    .MuiSlider-thumbColorPrimary {
        color:${theme.colors.primary600};
    }
    .MuiSlider-thumb:hover {
        box-shadow:none;
    }
    //Hover Label when moving
    .MuiSlider-valueLabel {
        color:${theme.colors.white};
        background-color:${theme.colors.primary600};
    }
    .MuiSlider-rail {
        color:${theme.colors.primary100};

    }
    .MuiSlider-track {
        color:${theme.colors.primary400};

    }
}

`

export default GlobalStyle
