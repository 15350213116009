import { css } from 'styled-components'

export const media = {
	phone: (...args) => {
		return css`
			@media screen and (max-width: 768px) {
				${css(...args)};
			}
		`
	},
	tabletMid: (...args) => {
		return css`
			@media screen and (max-width: 768px) {
				${css(...args)};
			}
		`
	},
	tablet: (...args) => {
		return css`
			@media screen and (max-width: 1023px) {
				${css(...args)};
			}
		`
	},
	desktopMinPlus: (...args) => {
		return css`
			@media screen and (max-width: 1024px) {
				${css(...args)};
			}
		`
	},
	desktopSm: (...args) => {
		return css`
			@media screen and (max-width: 1359px) {
				${css(...args)};
			}
		`
	},
	desktopLg: (...args) => {
		return css`
			@media screen and (min-width: 1360px) and (max-width: 1920px) {
				${css(...args)};
			}
		`
	},
	desktopXl: (...args) => {
		return css`
			@media screen and (min-width: 1921px) {
				${css(...args)};
			}
		`
	},
}

// mediaNext is the newest set of breakpoints used in Kvika.is designs.
// We retain the legacy "media" breakpoints for scenarios that still require the older design rules.
export const mediaNext = {
	phone: (...args) => {
		return css`
			@media (max-width: 759px) {
				${css(...args)};
			}
		`
	},
	tablet: (...args) => {
		return css`
			@media (min-width: 760px) and (max-width: 959px) {
				${css(...args)};
			}
		`
	},
	desktopSm: (...args) => {
		return css`
			@media (min-width: 960px) and (max-width: 1279px) {
				${css(...args)};
			}
		`
	},
	desktopMd: (...args) => {
		return css`
			@media (min-width: 1280px) and (max-width: 1679px) {
				${css(...args)};
			}
		`
	},
	desktopLg: (...args) => {
		return css`
			@media (min-width: 1680px) {
				${css(...args)};
			}
		`
	}
}

export const devices = {
	ios: (args) => {
		return css`
			@supports (-webkit-text-size-adjust: none) {
				${css(args)};
			}
		`
	},
	safariDesktop: (...args) => {
		return css`
			@media not all and (min-resolution: 0.001dpcm) {
				@supports (-webkit-appearance: none) and (display: flow-root) {
					${css(...args)};
				}
			}
		`
	},
}
