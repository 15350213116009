import styled from "styled-components";
import Image from "next/image";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`

export const StyledImage = styled(Image)`
    cursor: pointer;
`