import { PrismicDocument } from '@prismicio/types'

import { routingPaths } from '../../constants/Constants'
import { IBaseSlice } from '../../types/prismic/baseTypes'
import { ILatestNewsArticlesSlice } from '../../types/prismic/prismicSliceTypes'
import PrismicClient from './Helpers'

const slicesWithDocumentData = ['latest_news_articles']

export const formatDocumentSlice = async (doc, deletedNews?: string[]) => {
	return {
		...doc,
		data: {
			...doc.data,
			body: await getSlicesFromDocument(doc, deletedNews),
		},
	}
}

const getSlicesFromDocument = async (
	document: PrismicDocument,
	deletedNews?: string[]
): Promise<IBaseSlice[]> => {
	if (!document.data?.body) return [] as IBaseSlice[]

	const slices = (await Promise.all(
		document.data.body.map((slice: IBaseSlice) =>
			slicesWithDocumentData.includes(slice.slice_type)
				? populateSlicesWithPrismicDocumentData(
						slice,
						document.lang,
						deletedNews
				  )
				: slice
		)
	).catch(() => [])) as IBaseSlice[]

	return slices.flat()
}

const populateSlicesWithPrismicDocumentData = async (
	document: IBaseSlice,
	language: string,
	deletedNews?: string[]
): Promise<IBaseSlice[] | IBaseSlice> => {
	switch (document.slice_type) {
		case 'latest_news_articles':
			return await populateLatestNewsSlice(
				document as ILatestNewsArticlesSlice,
				language,
				deletedNews
			)
		default:
			return []
	}
}

const populateLatestNewsSlice = async (
	document: ILatestNewsArticlesSlice,
	language: string,
	deletedNews?: string[]
): Promise<ILatestNewsArticlesSlice> => {
	let newsArticleSummary = await PrismicClient().getByType('newsarticle', {
		pageSize: document.primary.number_of_articles,
		page: 1,
		lang: language,
		orderings: {
			field: 'my.newsarticle.published_date',
			direction: 'desc',
		},
	})

	if (deletedNews && deletedNews.length > 0) {
		let deletedArticlesNumber = newsArticleSummary.results.filter(
			(article) => article.uid && deletedNews.includes(article.uid)
		).length

		let loopAgain = deletedArticlesNumber > 0 ? 1 : 0
		// loopAgain < 20 so it doesn't go to intinity
		while (loopAgain > 0 && loopAgain < 20) {
			newsArticleSummary = await PrismicClient().getByType('newsarticle', {
				pageSize: document.primary.number_of_articles + deletedArticlesNumber,
				page: 1,
				lang: language,
				orderings: {
					field: 'my.newsarticle.published_date',
					direction: 'desc',
				},
			})

			newsArticleSummary.results = newsArticleSummary.results.filter(
				(article) => !article.uid || !deletedNews.includes(article.uid)
			)

			if (
				newsArticleSummary.results.length ===
				document.primary.number_of_articles
			) {
				loopAgain = 0
			} else {
				deletedArticlesNumber =
					document.primary.number_of_articles +
					deletedArticlesNumber -
					newsArticleSummary.results.length
				loopAgain += 1
			}
		}
	}

	return {
		...document,
		newsArticlesPreview: newsArticleSummary.results.map((result) => {
			return {
				short_description: result.data.short_description,
				published_date: result.data.published_date,
				title: result.data.title,
				href: `/${routingPaths[language].news}/${result.uid}`,
				preview_image: result.data.preview_image
			}
		}),
	}
}
