import React from 'react'

import { FooterIcon, IFooterIconSlice } from './FooterIcon'
import { FooterIconLink, IFooterIconLinkSlice } from './FooterIconLink'
import { FooterLink, IFooterLinkSlice } from './FooterLink'
import { FooterText, IFooterParagraphSlice } from './FooterText'
import { FooterTextSection, IFooterTextSectionSlice } from './FooterTextSection'
import FooterIconRow, { IFooterIconRow } from '../../FooterIconsRow/FooterIconRow'

export type IFooterSlice =
	| IFooterIconLinkSlice
	| IFooterIconSlice
	| IFooterLinkSlice
	| IFooterParagraphSlice
	| IFooterTextSectionSlice
	| IFooterIconRow

interface Props {
	slices: IFooterSlice[]
	linkResolver?: (link: any) => string | undefined
}

const FooterSliceZone = ({ slices, linkResolver }: Props) => {
	return (
		<>
			{slices.map((slice, index) => {
				const key = `${slice.slice_type}-${index}`
				switch (slice.slice_type) {
					case 'footerlink':
						return <FooterLink linkResolver={linkResolver} key={key} slice={slice} />

					case 'footericonlink':
						return <FooterIconLink linkResolver={linkResolver} key={key} slice={slice} />

					case 'footertext':
						return <FooterText key={key} slice={slice} />

					case 'footertextsection':
						return <FooterTextSection key={key} slice={slice} />

					case 'footericon':
						return <FooterIcon linkResolver={linkResolver} key={key} slice={slice} />

					case 'iconsinsinglerow':
						return <FooterIconRow {...slice} />
					default:
						// eslint-disable-next-line
						console.log('FooterSliceType not implemented')
						return null
				}
			})}
		</>
	)
}

export default FooterSliceZone
