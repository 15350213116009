import * as React from 'react'

import { KvikaSVGProps } from './types'

const CollapsableArrow = ({
	height = 16,
	width = 16,
	fill = 'currentColor',
	rotate = 0,
}: KvikaSVGProps) => (
	<svg
		width='13'
		height='12'
		viewBox='0 0 13 12'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M6.97133 9.13782L12.3047 3.80448L11.362 2.86182L6.5 7.72382L1.638 2.86182L0.695333 3.80448L6.02867 9.13782C6.15369 9.2628 6.32323 9.33301 6.5 9.33301C6.67678 9.33301 6.84632 9.2628 6.97133 9.13782Z'
			fill='#907140'
		/>
	</svg>
)

export default CollapsableArrow
