import React from 'react'
import { IPrismicImage } from '../PrismicColumnFooter/FooterSliceZone/PrismicImage'
import { IPrismicLink } from '../../types/prismic/baseTypes'
import { Container, StyledImage } from './style'
import { useRouter } from 'next/router'
import { IPrismicWebLink } from '../../types/prismic/baseTypes'
export interface IFooterIconRow {
	slice_type: 'iconsinsinglerow',
	primary: {
		column: number
	}
	items: Array<{
		image: IPrismicImage
		link: IPrismicLink
	}>
}

const FooterIconRow: React.FC<IFooterIconRow> = ({ items }) => {
	const router = useRouter();

	const handeClick = (webLink?: IPrismicWebLink) => {
		if(webLink?.url) {
			router.push(webLink.url)
		}
	}
	return (
		<Container>
			{items.map((item, i) => (
				<StyledImage key={'Footer-single-row-icon' + i} src={item.image.url} width={24} height={24} alt='icon' onClick={()=>handeClick(item.link as IPrismicWebLink)} />
			))}
		</Container>
	)
}

export default FooterIconRow
